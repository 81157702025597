import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';

//import './index.scss';

type CookiesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const COOKIES_KEY = 'cookieAgreement';
const GTM_KEY = 'GTMAgreement';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Cookies = (componentProps: CookiesProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const [showCookies, setShowCookies] = React.useState<boolean | null>(null);
  const [cookiesSettings, setCookiesSettings] = React.useState<boolean>(false);
  const [GTMData, setGTMData] = React.useState<any>({
    ad_storage: {
      checked: true,
    },
    ad_user_data: {
      checked: true,
    },
    ad_personalization: {
      checked: true,
    },
    analytics_storage: {
      checked: true,
    },
  });

  React.useEffect(() => {
    checkCookies();
    setCookieIfCampaign();
  }, []);

  const acceptHandler = () => {
    setShowCookies(false);
    const expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime();
    // @ts-ignore
    ExpiringLocalStorage.set(COOKIES_KEY, 'true', expireDate);
    // - DEACTIVATED UNTIL DESIGN
    // ExpiringLocalStorage.set(GTM_KEY, GTMData, expireDate);
    // @ts-ignore
  };

  const checkCookies = () => {
    const cookies = ExpiringLocalStorage.get(COOKIES_KEY);
    const gtm = ExpiringLocalStorage.get(GTM_KEY);

    if (gtm) {
      setGTMData({
        // Code prepared to update individually in the future
        ad_storage: {
          checked: gtm?.ad_storage === 1,
        },
        ad_user_data: {
          // checked: gtm?.ad_user_data === 1,
          checked: gtm?.ad_storage === 1,
        },
        ad_personalization: {
          // checked: gtm?.ad_personalization === 1,
          checked: gtm?.ad_storage === 1,
        },
        analytics_storage: {
          // checked: gtm?.analytics_storage === 1,
          checked: gtm?.ad_storage === 1,
        },
      });
    }
    // - DEACTIVATED UNTIL DESIGN
    // if (cookies && gtm) {
    if (cookies) {
      setShowCookies(false);
    } else {
      setShowCookies(true);
    }
  };

  const setCookieIfCampaign = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const affId = urlParams.get('aff_id');

    if (affId) {
      // @ts-ignore
      ExpiringLocalStorage.set('aff_id', affId, 2);
    }

    const gclid = urlParams.get('gclid');
    if (gclid) {
      // @ts-ignore
      ExpiringLocalStorage.set('gclid', gclid, 2);
    }

    const sub_id = urlParams.get('sub_id');
    if (sub_id) {
      // @ts-ignore
      ExpiringLocalStorage.set('sub_id', sub_id, 2);
    }
  };

  const toggleSwitch = (e: React.MouseEvent<HTMLElement>) => {
    const newGTMData: any = { ...GTMData };
    const target = e.target as HTMLInputElement;

    // Code prepared to update individually in the future
    // newGTMData[target.name] = {
    //   checked: target.checked,
    // };

    newGTMData['ad_storage'] = {
      checked: target.checked,
    };
    newGTMData['ad_user_data'] = {
      checked: target.checked,
    };
    newGTMData['ad_personalization'] = {
      checked: target.checked,
    };
    newGTMData['analytics_storage'] = {
      checked: target.checked,
    };

    setGTMData(newGTMData);
  };

  const contextValue = React.useMemo(() => {
    return {
      showCookies: showCookies,
      cookiesSettings: cookiesSettings,
      GTMData: GTMData,
      acceptHandler: acceptHandler,
      settingsHandler: () => setCookiesSettings(!cookiesSettings),
      toggleSwitch: toggleSwitch,
    };
  }, [showCookies, acceptHandler]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Cookies;
